export function setElHeight(items) {
    const maxHeight = getMaxHeight(items)
    Array.from(items).forEach(item => {
        item.style.height = `${maxHeight}px`
    })
}

export function getMaxHeight(items) {
    return Math.max(...Array.from(items).map(item => item.clientHeight))
}

export function prefixUrl() {
    return localStorage.getItem('locale') != 'sk' ? ('/'+localStorage.getItem('locale')) : ''
}

export const convertImages = (query, callback) => {
    const images = document.querySelectorAll(query)

    images.forEach(image => {
        fetch(image.src)
            .then(res => res.text())
            .then(data => {
                const parser = new DOMParser()
                const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg')

                svg.querySelectorAll('g rect').forEach(rect => {
                    if (rect.getAttribute('fill') === '#6687A4') {
                        rect.classList.add('primary-rect')
                    }
                })

                if (image.id) svg.id = image.id
                if (image.className) svg.classList = image.classList

                image.parentNode.replaceChild(svg, image)
            })
            .then(callback)
            .catch(error => console.error(error))
    })
}
